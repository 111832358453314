import React from 'react';
import NavbarTwo from './component/Navbar/NavbarTwo';
import FBanner from './component/Banner/frelencerBanner';
// import PortfolioSlider from './component/Portfolio/PortfolioSlider';
// import ServiceTwo from './component/ServiceTwo';
// import TestimonialTwo from './component/Testimonial/TestimonialTwo';
// import ClientslogoTwo from './component/ClientslogoTwo';
import BlogTwo from './component/Blog/BlogTwo';
import ContactTwo from './component/ContactTwo';
import SkillTwo from './component/Skill/SkillTwo';
import Skill from './component/Skill/Skill';
import FooterTwo from './component/FooterTwo';
import jhonData from './component/jhonData';

export const Homefrelencer = () => (
  <div className="body_wrapper">
    <NavbarTwo mClass="menu_two" mainlogo="me.png" stickylogo="me.png"/>  
    <FBanner jhonData={jhonData}/>
    <SkillTwo/>
    <Skill/>
    <BlogTwo/>
    <ContactTwo jhonData={jhonData}/>
    <FooterTwo jhonData={jhonData}/>
  </div>
)
