import React, { Component } from "react";
import SectionTitleTwo from "../Banner/SectionTitleTwo";
import BlogTwoitems from "../Blog/BlogTwoitems";
import Reveal from "react-reveal/Reveal/";
class BlogTwo extends Component {
  render() {
    return (
      <section className="blog_area_two" id="blog">
        <div className="container">
          <SectionTitleTwo
            tCenter="text-center"
            stitle="My Blog"
            btitle="News and Updates"
          />
          <Reveal effect="fadeInUp" duration={1000}>
            <div className="row">
              <BlogTwoitems
                bTitle="AWS Architecture Hosting This Website"
                bDetails="A fun and powerful way to host websites. Amazon Web Services has many cool services from Quantum Computing to Machine Learning to Virtual Machines."
                btnText="View"
                image="cloudComputing.jpg"
                Pdata="Nov 16" 
                hashTag=" #AWS #Certified #CloudPracticioner"
                link="/aws"
              />
              <BlogTwoitems
                bTitle="Streaming/Video recording app using MERN stack"
                bDetails="Twitch.tv is an amazing app and I used the idea to recreate it myseslf."
                btnText="Read More"
                image="post-img2.jpg"
                Pdata="Jan 21"
                hashTag="#JS #Mongoose #Express #RESTful #API #React #Redux"
                link="/final-project"

              />
            </div>
          </Reveal>
        </div>
      </section>
    );
  }
}
export default BlogTwo;
