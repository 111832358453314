import React, { Component } from "react";
import SplitText from "react-pose-text";

const charPoses = {};
class SectionThree extends Component {
  render() {
    return (
      <div className="section fp-section fp-tabble section_one">
        <div className="slider_container">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="slider_content slider_content_three">
                  <h6>
                    {/* <span className="br" /> */}
                    <SplitText charPoses={charPoses}>"Alone we can do so little; together we can do so much" - Helen Keller</SplitText>
                  </h6>
                  <h2>
                    <SplitText charPoses={charPoses}>
                      My projects flow
                    </SplitText>
                  </h2>
                  <p>
                    Begins with the end user streaming a live video from OBS to an RTMP
                    server on port 1935. A react app then runs the live video for all to see using a
                    simple HTML 5 flash player on HTTP port 8000.
                  </p>
                  <a href="https://github.com/ConnorLakour/MSD-Final-Project-React-Redux" className="see_btn" data-text="See Projects">
                    See more
                    <span className="arrow">
                      <span className="line" />
                    </span>
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="slider_image_inner">
                  <span />
                  <img
                    src={require("../../image/finalProjectsFlow.png")}
                    alt="Project flow chart"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionThree;
