import React from "react";
// import emailjs from "emailjs-com";
import axios from "axios";
// import { data } from "isotope-layout";
class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        name: "",
        messageTitle: "",
        email: "",
        message: ""
      },
      name: "",
      messageTitle: "",
      email: "",
      message: "",
      submitted: false
    };
  }

  handleChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    let errors = this.state.errors;

    switch (name) {
      case "name":
        errors.name = value.length === 0 ? "Name is not empty" : "";
        break;
      case "messageTitle":
        errors.messageTitle =
          value.length < 5 ? "Subject must be 5 characters" : "";
        break;
      // case "phone":
      //   errors.phone = value.length < 5 ? "phone is not empty" : "";
      //   break;
      case "email":
        errors.email = value.length < 5 ? "Subject is not empty" : "";
        let appos = value.indexOf("@");
        let dots = value.lastIndexOf(".");

        if (appos < 1 || dots - appos < 2) {
          errors.email = "please enter valid email";
        }

        break;

      default:
        break;
    }
    this.setState({ errors, [name]: value });
  };

  submitHandler = e => {

    e.preventDefault();

    if (
      this.state.errors.name.length === 0 &&
      this.state.errors.messageTitle.length === 0 &&
      this.state.errors.message.length === 0 &&
      this.state.errors.email.length === 0 &&
      this.state.messageTitle.length !== 0 &&
      this.state.message.length !== 0 &&
      this.state.email.length !== 0 &&
      this.state.name.length !== 0
    ) {
      const dataObj = {};

      dataObj.name = this.state.name;
      dataObj.messageTitle = this.state.messageTitle;
      dataObj.message = this.state.message;
      dataObj.email = this.state.email;

      axios
        .post(
          "https://mjfcz0o3ic.execute-api.us-east-1.amazonaws.com/v1/contact",
          dataObj
        )
        .then(res => {
          //  console.log("Response: " + JSON.stringify(res));
        })
        .catch(err => {});

        this.setState({submitted: true})
      alert("Form has been sent successfully!");
    } else {
      alert("Form is invalid " + this.state.errors.messageTitle);
    }
    // emailjs
    //   .sendForm(
    //     "gmail",
    //     "template_zo1q2mh",
    //     e.target,
    //     "user_vvQtVRIgqRETJC2JHOJz9"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       alert("form is valid");
    //     },
    //     (error) => {
    //       console.log(error.text);
    //       alert("form is invalid");
    //     }
    //   );
  };

  render() {
    const { errors } = this.state;
    return (
      <div>
         {!this.state.submitted &&
        <form onSubmit={this.submitHandler.bind(this)} className="form_class">
          <div className="row">
            <div className="col-lg-6">
              <input
                type="text"
                id="name"
                name="messageTitle"
                className="form-control"
                placeholder="Message Title*"
                onChange={this.handleChange}
              />
              <p>
                {errors.messageTitle}
              </p>
            </div>
            <div className="col-lg-6">
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder="Your Email*"
                onChange={this.handleChange}
              />
              <p>
                {errors.email}
              </p>
            </div>
            <div className="col-lg-6">
              <input
                type="text"
                id="subject"
                name="name"
                className="form-control"
                placeholder="Your Name*"
                onChange={this.handleChange}
              />
              <p>
                {errors.name}
              </p>
            </div>
          </div>
          <textarea
            name="message"
            id="message"
            className="form-control"
            rows="6"
            placeholder="Your Message ..."
            onChange={this.handleChange}
          />

         
            <button type="submit" className="btn send_btn theme_btn">
              Send Message
            </button>
        </form>
            }
      </div>
    );
  }
}

export default Form;
