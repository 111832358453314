import React, { Component } from "react";
import SplitText from "react-pose-text";

const charPoses = {};

class SectionTwo extends Component {
  render() {
    return (
      <div className="section fp-section fp-tabble">
        <div className="slider_container">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="slider_content slider_content_two">
                  <h6 style={{margin: '10px'}}>
                    <span className="br" />
                    <SplitText charPoses={charPoses} >"The cloud services companies of all sizes…The cloud is for everyone. The cloud is a democracy." - Marc Benioff</SplitText>
                  </h6>
                 <br></br>
                    <p className="section-items" style={{fontSize:'25px'}}>   My website architecture </p>
                  
                  <ul style={{color: 'black' , fontSize: '18px', margin: '10px'}}>
                    <li className="section-items">
                      Godaddy - A web hosting company and registrar. AWS name
                      servers are registered here.
                    </li >
                    <li className="section-items">
                      Route 53 - DNS web service that makes requests to
                      CloudFront. Certificate's CNAME record is verfied here.
                      {/* </p> */}
                    </li>
                    <li className="section-items">
                      {/* <p className="section-items"> */}
                      AWS Certificate Manager - Issues a HTTPS/TLS certificate.
                      {/* </p> */}
                    </li>
                    <li className="section-items">
                      {/* <p className="section-items"> */}
                      CloudFront - Delivers content over the internet to anyone
                      in the world.
                      {/* </p> */}
                    </li>
                    <li className="section-items">
                      {/* <p className="section-items"> */}
                      S3 - A place to host the React application.
                      {/* </p> */}
                    </li>
                    <li className="section-items">
                      {/* <p className="section-items"> */}
                      API Gateway - Acts as a front door. Monitors, maintains
                      and deploys RESTful API.
                      {/* </p> */}
                    </li>
                    <li className="section-items">
                      {/* <p className="section-items"> */}
                      Lambda - RESTful API.
                      {/* </p> */}
                    </li>
                    <li className="section-items">
                      {/* <p className="section-items"> */}
                        SNS - Messaging system, sends data to the email
                        provided.
                      {/* </p> */}
                    </li>
                    <li className="section-items">
                      {/* <p className="section-items"> */}
                        SQS - Queue service, SNS pushes message into the Queue. Decopules application.{" "}
                      {/* </p> */}
                    </li>
                    <li className="section-items">
                      {/* <p className="section-items"> */}
                        Lambda - Pulls message from SQS, sends data to database.
                      {/* </p> */}
                    </li>
                    <li className="section-items">
                      {/* <p className="section-items"> */}
                        DynamoDB - NoSQL database 
                        {/* </p> */}
                    </li>
                  </ul>
                  {/* </div> */}

                  {/* <h2 >
                    <SplitText charPoses={charPoses}>Frelencer</SplitText>
                  </h2>  */}
                  {/* <p>
                    Start using our easy-to-use tools with multiple options to
                    improve.
                  </p> */}
                   {/* <a href="#/" className="see_btn" data-text="See Projects"> 
                    See Projects
                    <span className="arrow">
                      <span className="line"></span>
                    </span>
                  </a> */}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="slider_image_inner">
                  <span />
                  <img src={require("../../image/awsDiagram.jpg")} alt="" width="850" height="550"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SectionTwo;
