import React, { Component } from "react";

class BlogTwoitems extends Component {
  render() {
    let { bTitle, bDetails, btnText, image, Pdata, hashTag, link } = this.props;
    console.log(link)
    return (
      <div className="col-lg-6 col-md-6" >
        <div className="blog_post" >
          <div className="blog_img">
            <img
              className="img-fluid"
              src={require("../../image/" + image)}
              alt=""
            />
            <div className="post_date">
              {Pdata}
            </div>
          </div>
          <div className="post_content">
            <div className="blog-meta">
              <span>
                <i className="icon_tags_alt" />
               {hashTag} 

              </span>
              {/* <span>
                                <i className="icon_chat_alt"></i>
                                <a href="/#">0 comment</a>
                            </span> */}
            </div>
            <a href={link}>
              <h2>
                {bTitle}
              </h2>
            </a>
            <p>
              {bDetails}
            </p>
            {/* <Link to="/aws"></Link> */}
            <a href={link} className="read_btn"
            
            >
              {btnText}
              <i className="arrow_right" />{" "}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default BlogTwoitems;
